import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() screenFilterForm: FormGroup;
  @Input() styleType: String = 'rounded';
  @Input() paramStartDate: string; // format: 'DD/MM/YYYY'
  @Input() paramEndDate: string; // format: 'DD/MM/YYYY'

  constructor(
    private fb: FormBuilder,
  ) {}
  private componentDestroyed$: Subject<void> = new Subject();

  dateRangeForm = this.fb.group({
    startDate: [null as Date | null, [Validators.required]],
    endDate: [null as Date | null, [Validators.required]]
  });

  ngOnInit(): void {
    this.initValueChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paramStartDate || changes.paramEndDate) {
      this.setInitialDateRange();
    }
  }

  private setInitialDateRange() {
    if (this.paramStartDate) {
      const startDate = moment(this.paramStartDate, 'DD/MM/YYYY').toDate();
      this.dateRangeForm.get('startDate').setValue(startDate);
    }

    if (this.paramEndDate) {
      const endDate = moment(this.paramEndDate, 'DD/MM/YYYY').toDate();
      this.dateRangeForm.get('endDate').setValue(endDate);
    }
  }


  public initValueChange() {
    merge(
      this.dateRangeForm.get("startDate").valueChanges,
      this.dateRangeForm.get("endDate").valueChanges,
    )
    .pipe(
      debounceTime(500),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(
      () => {
        const startDate = moment(this.dateRangeForm.value.startDate).format('DD/MM/YYYY');
        const endDate = moment(this.dateRangeForm.value.endDate).format('DD/MM/YYYY');

        this.screenFilterForm.get('startDate').setValue(startDate);
        this.screenFilterForm.get('endDate').setValue(endDate);
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }

}
