import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ScreenService } from '@core/services/screen/screen.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenTypeComponent } from '../screen-type/screen-type.component';

@Component({
  selector: 'app-screen-information',
  templateUrl: './screen-information.component.html',
  styleUrls: ['./screen-information.component.scss'],
})
export class ScreenInformationComponent implements OnInit, OnDestroy {
  @Input() screenForm: FormGroup;
  @Input() screenPriceLib: [];
  @Input() screenTypes: [];
  @Input() selectedType = [];
  @Input() screenDurCalMax;
  @Input() individualTypes;
  @Input() screenTypeToDelete;
  @Input() screenTypeToInsert;
  @Input() screenInClosureDate;
  @Input() data;
  loading = false;
  forcableIsMixte = false;
  libFormated: { display_input: string; display_label: string }[] = [];
  screenHourDiffForm: FormGroup;
  screenHourForm: FormGroup;
  private componentDestroyed$: Subject<void> = new Subject();

  readonly forcableChoices: { display: string; id: string }[] = [
    { display: 'Mixte', id: 'Mixte' },
    { display: 'Non Forçable', id: 'N' },
    { display: 'Forçable', id: 'Y' },
  ];

  constructor(public screenService: ScreenService, public dialog: MatDialog, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.setDefaultValue();
    this.formatScreenPriceLib();
    this.setScreenHourDiff();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  initForm(): void {
    this.screenHourDiffForm = this.fb.group({
      screenHourDiff: [
        this.screenForm?.value?.screenHourDiff ? this.screenForm.value.screenHourDiff : null,
        [Validators.required, Validators.maxLength(5)],
      ],
    });

    this.screenHourForm = this.fb.group({
      screenHour: [
        this.screenForm?.value?.screenHour ? this.screenForm.value.screenHour : null,
        [Validators.required, Validators.maxLength(5)],
      ],
    });
  }

  setDefaultValue(): void {
    if (this.data.isCreating) {
      this.screenForm.get('screenForcable').setValue('Y', { emitEvent: false });
    }
    if (this.screenForm.get('screenForcable').value === 'Mixte') {
      this.forcableIsMixte = true;
    }
  }

  setScreenHourDiff(): void {
    this.screenHourDiffForm
      .get('screenHourDiff')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.screenForm.get('screenHourDiff').setValue(this.screenHourDiffForm.get('screenHourDiff').value);
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }

  formatScreenPriceLib(): void {
    this.screenPriceLib.forEach((element: string) => {
      this.libFormated = [
        ...this.libFormated,
        { display_label: this.capitalizeFirstLetter(element.replace('_', ' ')), display_input: element },
      ];
    });
  }

  capitalizeFirstLetter(string): any {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  openTypeDialog() {
    const dialogRef = this.dialog.open(ScreenTypeComponent, {
      data: {
        screenType: this.screenTypes,
        selectedType: this.selectedType,
        screenTypeToInsert: this.screenTypeToInsert,
        screenTypeToDelete: this.screenTypeToDelete,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(selectedType => {
        if (this.data.isCreating) {
          this.selectedType = selectedType;
        }
      });
  }

  removeScreenType(type): void {
    this.selectedType.forEach(element => {
      if (type === element) {
        const indexType = this.selectedType.indexOf(element);
        element.isSelected = false;
        this.selectedType.splice(indexType, 1);
      }
    });

    this.individualTypes.forEach(element => {
      if (type === element) {
        const indexType = this.individualTypes.indexOf(element);
        element.isSelected = false;
        this.individualTypes.splice(indexType, 1);
      }
    });

    this.screenTypeToDelete.push(type);
  }

  /**
   * Select forcable when updating screen
   * @param choice
   */
  selectedForcable(choice): void {
    this.screenForm.get('screenForcable').setValue(choice.id);
  }

  /**
   * Select forcable when creating screen
   * @param event
   */
  selectedScreenForcable(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.screenForm.get('screenForcable').setValue('Y');
    } else {
      this.screenForm.get('screenForcable').setValue('N');
    }
  }
}
