import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
/* Flex layout */
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
/* Material modules */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DEFAULT_LOCALE } from '@app/app.constants';
import { DirectivesModule } from '@core/directives/directives.module';
import { ContractCommentComponent } from '@shared/contract/contract-item/contract-comment/contract-comment.component';
import { ChannelFilterPipe } from '@shared/pipes/channel-filter.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DayFilterComponent } from './components/day-filter/day-filter.component';
import { FileUploadInputComponent } from './components/file-upload-input/file-upload-input.component';
/* Stateless components */
import { MainHeaderComponent } from './components/headers/main-header/main-header.component';
import { HourFilterComponent } from './components/hour-filter/hour-filter.component';
import { InfoAlertComponent } from './components/info-alert/info-alert.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SavePlanComponent } from './components/save-plan/save-plan.component';
import { ContractItemComponent } from './contract/contract-item/contract-item.component';
import { ContractListComponent } from './contract/contract-list/contract-list.component';
import { ContractComponent } from './contract/contract.component';
import { CommentAddDialogComponent } from './dialog/comment-add-dialog/comment-add-dialog.component';
import { CommentEditDialogComponent } from './dialog/comment-edit-dialog/comment-edit-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { FilterPopupComponent } from './dialog/filter-popup/filter-popup.component';
import { FiltersDialogComponent } from './dialog/filters-dialog/filters-dialog.component';
import { RegionDialogComponent } from './dialog/region-dialog/region-dialog.component';
import { ScreenDialogComponent } from './dialog/screen-dialog/screen-dialog.component';
import { ScreenHistoricComponent } from './dialog/screen-dialog/screen-historic/screen-historic.component';
import { ScreenInformationComponent } from './dialog/screen-dialog/screen-information/screen-information.component';
import { ScreenSpotsComponent } from './dialog/screen-dialog/screen-spots/screen-spots.component';
import { ScreenTypeComponent } from './dialog/screen-dialog/screen-type/screen-type.component';
import { TvConfirmPopupComponent } from './dialog/tv-confirm-popup/tv-confirm-popup.component';
import { TvFileUploadPopupComponent } from './dialog/tv-file-upload-popup/tv-file-upload-popup.component';
import { TvSelectDatePopupComponent } from './dialog/tv-select-date-popup/tv-select-date-popup.component';
/* Pipes */
import { NumberFormaterPipe } from './pipes/number-formater.pipe';
import {
  TvFileUploadPopupProgComponent
} from '@shared/dialog/tv-file-upload-popup/tv-file-upload-popup.prog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSortModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
  ],
  exports: [
    // Modules
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSortModule,
    MatChipsModule,
    MatCheckboxModule,
    DragDropModule,
    // Components
    MainHeaderComponent,
    NavItemComponent,
    ConfirmDialogComponent,
    FileUploadInputComponent,
    TvConfirmPopupComponent,
    DateRangePickerComponent,
    DayFilterComponent,
    HourFilterComponent,
    // Pipes
    NumberFormaterPipe,
    ChannelFilterPipe,
    InfoAlertComponent,
    FilterPopupComponent,
    PaginatorComponent,
    RegionDialogComponent,
    TvFileUploadPopupComponent,
    TvFileUploadPopupProgComponent,
  ],
  declarations: [
    MainHeaderComponent,
    NavItemComponent,
    NumberFormaterPipe,
    SavePlanComponent,
    ConfirmDialogComponent,
    FileUploadInputComponent,
    ChannelFilterPipe,
    InfoAlertComponent,
    TvConfirmPopupComponent,
    FilterPopupComponent,
    DateRangePickerComponent,
    DayFilterComponent,
    HourFilterComponent,
    FiltersDialogComponent,
    PaginatorComponent,
    TvSelectDatePopupComponent,
    ScreenDialogComponent,
    ScreenInformationComponent,
    ScreenTypeComponent,
    ScreenSpotsComponent,
    TvFileUploadPopupComponent,
    TvFileUploadPopupProgComponent,
    ContractComponent,
    ContractListComponent,
    ContractItemComponent,
    ScreenHistoricComponent,
    RegionDialogComponent,
    ContractCommentComponent,
    CommentEditDialogComponent,
    CommentAddDialogComponent,
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: DEFAULT_LOCALE },
    { provide: MAT_DATE_LOCALE, useValue: DEFAULT_LOCALE },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class SharedModule {}
