export interface JsonContractItem {
  id: string;
  name: string;
  advertiserId: string;
  cgvReturnDate: string;
  cgvStateNum: string;
  cgvStatus: string;
  cgvLabel: string;
  stateContractId: number;
  stateContractLabel: string;
  CA: number;
  f2f3f5CA: number;
  regionCA: number;
  thematicCA: number;
  sponsorshipFtpCA: number;
  sponsorshipThematicCA: number;
  sponsorshipRegCA: number;
  sponsorshipSoReachCA: number;
  sponsorshipWebCA: number;
  webCA: number;
  OIWebCA: number;
  cgvContractYear: string;
  stateContractYear: string;
}

export class ContractItem {
  id: string;
  name: string;
  advertiserId: string;
  cgvReturnDate: string;
  cgvStateNum: string;
  cgvStatus: string;
  cgvLabel: string;
  stateContractId: number;
  stateContractLabel: string;
  CA: number;
  f2f3f5CA: number;
  regionCA: number;
  thematicCA: number;
  sponsorshipFtpCA: number;
  sponsorshipThematicCA: number;
  sponsorshipRegCA: number;
  sponsorshipSoReachCA: number;
  sponsorshipWebCA: number;
  webCA: number;
  OIWebCA: number;
  cgvContractYear: string;
  stateContractYear: string;

  constructor(jsonContractItem: JsonContractItem = null) {
    this.id = jsonContractItem.id;
    this.name = jsonContractItem.name;
    this.advertiserId = jsonContractItem.advertiserId;
    this.cgvReturnDate = jsonContractItem.cgvReturnDate;
    this.cgvStateNum = jsonContractItem.cgvStateNum;
    this.cgvStatus = jsonContractItem.cgvStatus;
    this.cgvLabel = jsonContractItem.cgvLabel;
    this.stateContractId = jsonContractItem.stateContractId;
    this.stateContractLabel = jsonContractItem.stateContractLabel;
    this.CA = jsonContractItem.CA;
    this.f2f3f5CA = jsonContractItem.f2f3f5CA;
    this.regionCA = jsonContractItem.regionCA;
    this.thematicCA = jsonContractItem.thematicCA;
    this.sponsorshipFtpCA = jsonContractItem.sponsorshipFtpCA;
    this.sponsorshipThematicCA = jsonContractItem.sponsorshipThematicCA;
    this.sponsorshipRegCA = jsonContractItem.sponsorshipRegCA;
    this.sponsorshipSoReachCA = jsonContractItem.sponsorshipSoReachCA;
    this.sponsorshipWebCA = jsonContractItem.sponsorshipWebCA;
    this.webCA = jsonContractItem.webCA;
    this.OIWebCA = jsonContractItem.OIWebCA;
    this.cgvContractYear = jsonContractItem.cgvContractYear;
    this.stateContractYear = jsonContractItem.stateContractYear;
  }
}
