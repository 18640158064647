import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ContractItem } from '@core/models/contract-item.model';
import { ContractFilter, ContractService } from '@core/services/contract.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ContractListComponent implements OnInit, OnDestroy {
  public isLoadingItems = true;
  public resultsLength = 0;
  public showClearButton = false;
  public searchFormControl = new FormControl();
  public yearFormControl = new FormControl();
  public items = new MatTableDataSource<ContractItem>([]);
  public displayedColumns: string[] = [
    'id',
    'name',
    'cgvReturnDate',
    'cgvStateNum',
    'cgvStatus',
    'cgvLabel',
    'stateContractLabel',
    'CA',
    'f2f3f5CA',
    'regionCA',
    'thematicCA',
    'sponsorshipFtpCA',
    'sponsorshipThematicCA',
    'sponsorshipRegCA',
    'sponsorshipSoReachCA',
    'sponsorshipWebCA',
    'webCA',
    'OIWebCA',
    'open',
  ];

  columnsVisibility = {
    CA: true,
    f2f3f5CA: true,
    regionCA: true,
    thematicCA: true,
    sponsorshipFtpCA: true,
    sponsorshipThematicCA: true,
    sponsorshipRegCA: true,
    sponsorshipSoReachCA: true,
    sponsorshipWebCA: true,
    webCA: true,
    OIWebCA: true,
  };

  public filter: ContractFilter = {
    year: this.getCurrentYear(),
    searchElementById: this.searchFormControl.value || '',
    searchElementByName: this.searchFormControl.value || '',
  };

  componentDestroyed$: Subject<void> = new Subject();

  constructor(private contractService: ContractService, private router: Router) {}

  ngOnInit(): void {
    this.setCurrentYearFilter();
    this.getContractList(this.filter);
    this.handleContractFilterValueChange();
    this.handleYearFilterValueChange();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  getCurrentYear() {
    return new Date().getFullYear().toString();
  }

  setCurrentYearFilter() {
    this.yearFormControl.setValue(this.getCurrentYear());
  }

  getContractList(filter: ContractFilter) {
    this.isLoadingItems = true;

    this.contractService
      .getContractList(filter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(contracts => {
        this.items = new MatTableDataSource<ContractItem>(contracts);
        this.isLoadingItems = false;
      });
  }

  handleYearDatepickerValueChange(normalizedYear: Moment, dp: any) {
    dp.close();
    this.yearFormControl.setValue(normalizedYear);
    this.filter.year = normalizedYear.year().toString();
  }

  handleYearFilterValueChange() {
    this.yearFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      this.filter.year = value.year().toString();
      this.getContractList(this.filter);
    });
  }

  handleContractFilterValueChange() {
    this.searchFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      if (parseInt(value.trim())) {
        this.filter.searchElementById = value.trim();
        this.filter.searchElementByName = '';
      } else {
        this.filter.searchElementById = '';
        this.filter.searchElementByName = value.trim();
      }
      this.getContractList(this.filter);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    const sortedData = this.items.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
    this.items.data = [...sortedData];
  }

  onIconClick(contract) {
    this.router.navigate(['/commercial/contract/' + this.filter.year + '/' + contract.id]);
  }

  toggleColumn() {
    // this.columnsVisibility[column] = !this.columnsVisibility[column];
  }
}
