import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContractComment } from '@core/models/contract-comment/contract-comment.model';
import { ContractItem } from '@core/models/contract-item.model';
import { TvConfirmationData } from '@core/models/tv-confirmation-data.model';
import { AuthService } from '@core/services/auth.service';
import { ContractCommentService } from '@core/services/contract-comment/contract-comment.service';
import { ContractFilter, ContractService } from '@core/services/contract.service';
import { CommentAddDialogComponent } from '@shared/dialog/comment-add-dialog/comment-add-dialog.component';
import { CommentEditDialogComponent } from '@shared/dialog/comment-edit-dialog/comment-edit-dialog.component';
import { TvConfirmPopupComponent } from '@shared/dialog/tv-confirm-popup/tv-confirm-popup.component';
import { Subscription } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-contract-comment',
  templateUrl: './contract-comment.component.html',
  styleUrls: ['./contract-comment.component.scss'],
})
export class ContractCommentComponent implements OnInit, OnDestroy {
  private yearUrlIndex = 3;
  private contractIdUrlIndex = 4;
  public currentContract: ContractItem;
  public searchFormControl = new FormControl();
  public contractFilter: ContractFilter;
  public currentUserData;
  public comments: ContractComment[];
  private contractId: number;
  panelOpenState: boolean;
  commentIsEmpty: boolean;
  currentUserName: string;
  private contractListSubscription: Subscription;
  private commentSubscription: Subscription;
  private userDataSubscription: Subscription;
  private startDate: Date;
  private endDate: Date;
  screenFilterForm: any;
  public filteredComments: ContractComment[] = [];

  constructor(
    private contractCommentService: ContractCommentService,
    private userDataService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private contractService: ContractService,
    private fb: FormBuilder
  ) {
    this.screenFilterForm = this.fb.group({
      startDate: [null],
      endDate: [null],
    });
  }
  ngOnInit(): void {
    this.handleUserDataSubscription();
    this.getContractFilterParams();
    this.handleContractSubscription(this.contractFilter);

    this.searchFormControl.valueChanges.pipe(debounceTime(300)).subscribe(searchTerm => {
      this.filterComments(searchTerm);
    });

    this.screenFilterForm.get('startDate').valueChanges.subscribe(() => {
      this.filterComments(this.searchFormControl.value);
    });

    this.screenFilterForm.get('endDate').valueChanges.subscribe(() => {
      this.filterComments(this.searchFormControl.value);
    });
  }

  filterCommentsByDate(): void {
    const startDate = this.screenFilterForm.get('startDate').value;
    const endDate = this.screenFilterForm.get('endDate').value;

    if (!startDate && !endDate) {
      this.filteredComments = [...this.comments];
    } else {
      this.filteredComments = this.comments.filter(comment => {
        const commentDate = new Date(comment.commentDate);
        return (!startDate || commentDate >= startDate) && (!endDate || commentDate <= endDate);
      });
    }

    this.commentIsEmpty = this.filteredComments.length === 0;
  }

  filterComments(searchTerm: string): void {
    const startDate = this.screenFilterForm.get('startDate').value;
    const endDate = this.screenFilterForm.get('endDate').value;

    let filteredComments = [...this.comments];

    if (startDate || endDate) {
      filteredComments = filteredComments.filter(comment => {
        const commentDate = new Date(comment.commentDate);
        return (!startDate || commentDate >= startDate) && (!endDate || commentDate <= endDate);
      });
    }

    if (searchTerm) {
      filteredComments = filteredComments.filter(
        comment =>
          comment.body.toLowerCase().includes(searchTerm.toLowerCase()) || comment.author.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    this.filteredComments = filteredComments;
    this.commentIsEmpty = this.filteredComments.length === 0;
  }

  ngOnDestroy(): void {
    this.contractListSubscription.unsubscribe();
    this.commentSubscription.unsubscribe();
    this.userDataSubscription.unsubscribe();
  }

  public handleContractSubscription(filter: ContractFilter): void {
    this.contractListSubscription = this.contractService.getContractList(filter).subscribe(
      contract => {
        if (contract[0]) {
          this.currentContract = contract[0];
          this.contractId = Number(this.currentContract.advertiserId);
          this.handleContractCommentSubscription();
        }
      },
      error => {
        console.error('Erreur lors de la récupération des contrats', error);
        this.snackBar.open('Erreur lors de la récupération des contrats', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-chip-error'],
        });
      }
    );
  }

  handleUserDataSubscription() {
    this.userDataSubscription = this.userDataService.getUserInfo().subscribe(userData => {
      this.currentUserName = `${userData.name}`;
    });
  }

  public getContractFilterParams(): any {
    this.contractFilter = {
      year: this.router.url.split('/')[this.yearUrlIndex],
      searchElementById: this.router.url.split('/')[this.contractIdUrlIndex] || '',
    };
  }

  openAddDialog(): void {
    const emptyComment: ContractComment = {
      id: null,
      author: this.currentUserName,
      body: '',
      commentDate: new Date(),
      contractId: Number(this.currentContract.advertiserId),
      bactif: 1,
    };

    const dialogRef = this.dialog.open(CommentAddDialogComponent, {
      data: { comment: emptyComment },
      panelClass: 'tv-ns-dialog-container',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.contractCommentService
          .addComment(result)
          .pipe(
            catchError(error => {
              console.error("Erreur lors de l'ajout du commentaire", error);
              this.snackBar.open("Erreur lors de l'ajout du commentaire", null, {
                duration: 4000,
                verticalPosition: 'top',
                panelClass: ['tv-chip-error'],
              });
              throw error;
            })
          )
          .subscribe({
            next: () => {
              this.snackBar.open('Commentaire ajouté avec succès', null, {
                duration: 4000,
                verticalPosition: 'top',
                panelClass: ['tv-chip-success'],
              });
              this.handleContractCommentSubscription();
            },
          });
      }
    });
  }

  openEditDialog(comment: ContractComment): void {
    if (this.currentUserName !== comment.author) {
      this.snackBar.open('Vous ne pouvez modifier que vos propres commentaires.', null, {
        duration: 4000,
        verticalPosition: 'top',
        panelClass: ['tv-chip-error'],
      });
      return;
    }
    const dialogRef = this.dialog.open(CommentEditDialogComponent, {
      data: { comment },
      panelClass: 'tv-ns-dialog-container',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateComment(result);
      }
    });
  }

  updateComment(comment: ContractComment): void {
    this.contractCommentService.updateComment(comment).subscribe(() => {
      this.snackBar.open('Commentaire mis à jour avec succès', null, {
        duration: 4000,
        verticalPosition: 'top',
        panelClass: ['tv-chip-success'],
      });
      this.handleContractCommentSubscription();
    });
  }

  deleteComment(commentId: string) {
    const confirmationData: TvConfirmationData = {
      title: 'Suppression',
      content: 'Êtes-vous sûr de vouloir supprimer le commentaire ?',
      okButtonTitle: 'Oui',
      cancelButtonTitle: 'Non',
    };
    const dialogRef = this.dialog.open(TvConfirmPopupComponent, {
      data: confirmationData,
      panelClass: 'tv-ns-dialog-container',
    });
    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation === true) {
        this.contractCommentService.deleteComment(commentId).subscribe(() => {
          this.snackBar.open('Commentaire supprimé avec succès', null, {
            duration: 4000,
            verticalPosition: 'top',
            panelClass: ['tv-chip-success'],
          });
          this.handleContractCommentSubscription();
        });
      }
    });
  }

  public handleContractCommentSubscription() {
    this.commentSubscription = this.contractCommentService.getCommentsFor1Contract(this.contractId.toString()).subscribe(
      data => {
        this.comments = data;
        this.filterCommentsByDate();
      },
      error => {
        this.snackBar.open('Erreur lors de la récupération des commentaires du contrat: ' + this.contractId, null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-chip-error'],
        });
      }
    );
  }
}
